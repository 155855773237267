html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  font: 400 18px/1.375 "Helvetica Neue", Helvetica, Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

* {
  box-sizing: border-box;
}

a,
a:link,
a:visited {
  color: #000000;
  text-decoration: none;
}

img,
svg {
  display: block;
  max-width: 100%;
  -webkit-transition: 0.1s ease;
  transition: 0.1s ease;
}

.menu-content__images img,
.menu-content__group-item-link img {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.07);
}
.menu-content__images:hover img,
.menu-content__group-item-link:hover img {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.04);
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
}

body {
  -webkit-box-align: stretch;
          align-items: stretch;
  background-color: #000000;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-pack: start;
          justify-content: flex-start;
  min-height: 100vh;
}

.menu-wrap {
  background-color: #ffffff;
}

.menu-header,
.menu-content,
.menu-footer__inner {
  padding: 15px;
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
}
@media (min-width: 600px) {
  .menu-header,
.menu-content,
.menu-footer__inner {
    padding: 25px;
  }
}
@media (min-width: 1000px) {
  .menu-header,
.menu-content,
.menu-footer__inner {
    padding: 50px;
  }
}

@media (min-width: 600px) {
  .menu-header {
    padding-top: 2rem;
  }
}
@media (min-width: 600px) {
  .menu-header {
    -webkit-box-align: start;
            align-items: flex-start;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
            justify-content: space-between;
    padding-top: 3rem;
  }
}
@media (min-width: 1000px) {
  .menu-header {
    padding-top: 5rem;
  }
}

.menu-header__title {
  font: 400 28px/1.25 "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.01rem;
  color: #000000;
  -webkit-box-ordinal-group: 2;
          order: 1;
}
@media (min-width: 600px) {
  .menu-header__title {
    font-size: 36px;
  }
}
@media (min-width: 1000px) {
  .menu-header__title {
    font-size: 56px;
  }
}

.menu-header__ups-lockup {
  margin-bottom: 2rem;
  -webkit-box-ordinal-group: 3;
          order: 2;
  padding-top: 0.375rem;
}
@media (max-width: 600px) {
  .menu-header__ups-lockup {
    -webkit-box-align: start;
            align-items: flex-start;
    display: -webkit-box;
    display: flex;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
            justify-content: space-between;
    width: 100%;
  }
}
.menu-header__ups-lockup .logo {
  margin-bottom: 10px;
  width: 100px;
}
@media (min-width: 600px) {
  .menu-header__ups-lockup .logo {
    width: 140px;
  }
}

.menu-header__outlinks-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

a.menu-header__outlinks-link {
  color: #888888;
  display: block;
  font: 400 14px/1 "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 0.15rem 0;
  text-align: right;
}
a.menu-header__outlinks-link:hover {
  color: #000000;
}

.menu-content {
  padding-bottom: 15rem;
}

.menu-content__list {
  list-style: none;
  margin: 0 0 100px;
  padding: 0;
}

.menu-content__item:first-of-type {
  border-top: 3px solid #000000;
}

.menu-content__link {
  border-bottom: 1px solid #eaeaea;
  padding: 30px 0;
}
@media (min-width: 600px) {
  .menu-content__link {
    padding: 40px 0;
  }
}
@media (min-width: 1000px) {
  .menu-content__link {
    padding: 50px 0;
  }
}
.menu-content__link:hover img {
  display: block;
  width: 100%;
}

.menu-content__text {
  -webkit-box-flex: 1;
          flex-grow: 1;
  flex-shrink: 1;
}

.menu-content__images {
  flex-shrink: 0;
  text-align: right;
}

.menu-content__title {
  font: 400 21px/1.25 "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.01rem;
  margin-bottom: 0.5rem;
}
@media (min-width: 600px) {
  .menu-content__title {
    font-size: 28px;
  }
}
@media (min-width: 1000px) {
  .menu-content__title {
    font-size: 34px;
  }
}

.menu-content__small-title {
  font: 400 18px/1.25 "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.01rem;
  margin-bottom: 0.5rem;
}

.circle-arrow {
  height: 30px;
  width: 30px;
}
@media (min-width: 760px) {
  .circle-arrow {
    height: 40px;
    width: 40px;
  }
}
@media (min-width: 1000px) {
  .circle-arrow {
    height: 55px;
    width: 55px;
  }
}
.menu-content__item:hover .circle-arrow path:nth-child(1), .menu-content__group-item:hover .circle-arrow path:nth-child(1) {
  fill: #000000;
}
.menu-content__item:hover .circle-arrow path:nth-child(2),
.menu-content__item:hover .circle-arrow path:nth-child(3), .menu-content__group-item:hover .circle-arrow path:nth-child(2),
.menu-content__group-item:hover .circle-arrow path:nth-child(3) {
  stroke: #ffffff;
}

.menu-content__updated {
  color: #da0735;
  font: 400 12px/1.5 "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.01rem;
  margin-bottom: 1rem;
}

.menu-content__description {
  font: 400 13px/1.5 "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-bottom: 1rem;
}
@media (min-width: 1000px) {
  .menu-content__description {
    font-size: 15px;
  }
}
@media (min-width: 1000px) {
  .menu-content__description {
    width: calc((100% - 30px) / 2);
    font-size: 15px;
  }
}

.menu-content__item--no-thumbnail .menu-content__link {
  -webkit-box-align: stretch;
          align-items: stretch;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
}

.menu-content__item--has-thumbnail .menu-content__link {
  display: block;
}
@media (min-width: 450px) {
  .menu-content__item--has-thumbnail .menu-content__link {
    -webkit-box-align: stretch;
            align-items: stretch;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
}
.menu-content__item--has-thumbnail .menu-content__text {
  -webkit-box-flex: 1;
          flex-grow: 1;
  -webkit-box-ordinal-group: 2;
          order: 1;
  padding-right: 50px;
}
@media (min-width: 450px) {
  .menu-content__item--has-thumbnail .menu-content__text {
    width: 70%;
  }
}
.menu-content__item--has-thumbnail .menu-content__text .circle-arrow {
  margin-top: auto;
}
.menu-content__item--has-thumbnail .menu-content__text-inner {
  -webkit-box-align: stretch;
          align-items: stretch;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  height: 100%;
  -webkit-box-pack: start;
          justify-content: flex-start;
  width: 100%;
}
@media (min-width: 1000px) {
  .menu-content__item--has-thumbnail .menu-content__text-inner {
    width: 75%;
  }
}
.menu-content__item--has-thumbnail .menu-content__description {
  margin-bottom: 1rem;
  max-width: none;
  width: 100%;
}
.menu-content__item--has-thumbnail .menu-content__images {
  margin-bottom: 1rem;
  -webkit-box-ordinal-group: 3;
          order: 2;
}
@media (min-width: 450px) {
  .menu-content__item--has-thumbnail .menu-content__images {
    width: calc((100% - 30px) / 2);
    flex-shrink: 0;
    margin-bottom: 0;
  }
}

.menu-content__group {
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 100px;
}

.menu-content__group-head {
  border-bottom: 3px solid #000000;
  font: 800 24px/1.2 "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-bottom: 30px;
  padding-bottom: 0.25rem;
}

.menu-content__group-pages {
  -webkit-box-align: start;
          align-items: flex-start;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: start;
          justify-content: flex-start;
  margin-bottom: 20px;
}

.menu-content__group-item {
  width: calc((100% - 30px) / 2);
  margin-bottom: 30px;
  margin-right: 30px;
}
.menu-content__group-item:nth-of-type(2n) {
  margin-right: 0;
}
@media (min-width: 600px) {
  .menu-content__group-item {
    width: calc((100% - 60px) / 3);
  }
  .menu-content__group-item:nth-of-type(2n) {
    margin-right: 30px;
  }
  .menu-content__group-item:nth-of-type(3n) {
    margin-right: 0;
  }
}

.menu-content__group-item-link {
  display: block;
}

@media (min-width: 760px) {
  .menu-content__small-text {
    -webkit-box-align: start;
            align-items: flex-start;
    display: -webkit-box;
    display: flex;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .menu-content__small-text .circle-arrow {
    flex-shrink: 0;
    margin-left: 30px;
  }
}

.menu-content__small-text-inner {
  -webkit-box-flex: 1;
          flex: 1 1 auto;
}

.menu-content__small-thumb {
  margin-bottom: 15px;
}

.menu-content__small-title {
  font: 400 18px/1.25 "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.01rem;
}

.menu-content__small-updated {
  color: #da0735;
  font: 400 12px/1.5 "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.01rem;
}

.menu-content__small-description {
  font: 400 13px/1.5 "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-bottom: 30px;
}
@media (min-width: 1000px) {
  .menu-content__small-description {
    font-size: 15px;
  }
}

.menu-footer {
  background-color: #000000;
  margin-top: auto;
}
.menu-footer p {
  color: #ffffff;
}
.menu-footer a {
  color: #ffffff;
  font-weight: bold;
}